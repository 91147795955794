import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/header";

const query = graphql`
  query gerihteData {
    pageContent: allFile(filter: { relativePath: { eq: "gerihte.md" } }) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            background {
              image {
                childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              text
            }
            content {
              heading
              text
              images {
                image {
                  childImageSharp {
                    fluid(maxWidth: 520, quality: 100) {
                      ...GatsbyImageSharpFluid
                      originalName
                    }
                  }
                }
              }
            }
            file {
              publicURL
            }
          }
        }
      }
    }
    menus: allFile(filter: { relativePath: { eq: "menu.md" } }) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            items {
              subtitle
              title
              list {
                heading
                price
                text
              }
            }
          }
        }
      }
    }
    menu: file(relativePath: { eq: "Menukarte_IN.pdf" }) {
      publicURL
    }
    menuOut: file(relativePath: { eq: "Menukarte_OUT.pdf" }) {
      publicURL
    }
  }
`;

const Gerihte = () => {
  const data = useStaticQuery(query);
  const menus = data.menus.nodes[0].childMarkdownRemark.frontmatter.items;
  const pageContent = data.pageContent;
  const pageData = pageContent.nodes[0].childMarkdownRemark.frontmatter;
  const menu = data.menu;
  const menuOut = data.menuOut;

  const menuData = menus.map((menu, index) => {
    return {
      id: index,
      heading: menu.title,
      subheading: menu.subtitle,
      content: menu.list,
      expanded: false,
    };
  });

  const [boxes, setBoxes] = useState(menuData);

  const toggleBox = (id) => {
    const copyBoxes = [...boxes];
    const findBox = copyBoxes.find((box) => box.id === id);
    findBox.expanded = !findBox.expanded;
    setBoxes(copyBoxes);
  };

  return (
    <Layout>
      <SEO
        title="Gerichte"
        description="Hole deine Bestellung im Fit ó Fago ab. Ohne Stress, ohne Schlange stehen."
      />
      <Header
        image={pageData.background.image.childImageSharp.fluid}
        heading={pageData.background.text}
      />
      <div className="page__content pb-0 out-menu">
        <div className="container">
          <h3 className="page__title">{pageData.content.heading}</h3>
          <p
            className="text-center"
            dangerouslySetInnerHTML={{ __html: pageData.content.text }}
          />
          <div className="btn-center-container">
            <a
              href={menuOut.publicURL}
              className="btn btn-green transparent"
              target="_blank"
            >
              MenÜ to-go
            </a>
          </div>
        </div>
        <div className="page__menu">
          <div className="container in-menu">
            <h3 className="page__title">Menü</h3>
            <div className="btn-center-container">
              <a
                href={menu.publicURL}
                className="btn btn-green transparent"
                target="_blank"
              >
                MenÜ VOR ORT
              </a>
            </div>
            <div className="page__menu--content">
              <div className="page__menu--images">
                {pageData.content.images.map((img, index) => (
                  <div className="image-container" key={index}>
                    <Img
                      fluid={img.image.childImageSharp.fluid}
                      className="image"
                      alt={img.image.childImageSharp.fluid.originalName}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Gerihte;
